<template>
  <!-- <div
    class="pager"
    :class="{'show': y > 250}"
  > -->
  <div :class="{'pager_float': floating, 'pager_embed': !floating, 'show': true}">
    <b-pagination-nav
      v-model="testpage"
      class="mt-4"
      size="lg"
      :number-of-pages="pages"
      :link-gen="linkGen"
      use-router
    />
  </div>
</template>

<script>
import { useWindowScroll } from '@vueuse/core'

import {
  reactive, toRefs, watch,
} from '@vue/composition-api'

import {
  BPaginationNav,
} from 'bootstrap-vue'

export default {
  components: {
    BPaginationNav,
  },

  props: {
    page: {
      type: Number,
      default: 1,
    },
    pages: {
      type: Number,
      default: 1,
    },
    floating: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const settings = reactive({
      testpage: props.page,
    })
    const { y } = useWindowScroll()

    function linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    }

    function setCurrentPage(p) {
      emit('pager-page', p)
    }

    watch(props, p => {
      settings.testpage = p.page
      setCurrentPage(settings.testpage)
    }, {
      immediate: true,
    })

    watch(settings, p => {
      /* eslint-disable no-console */
      console.log(p)
      /* eslint-enable no-console */
      setCurrentPage(settings.testpage)
    }, {
      immediate: true,
    })

    return {
      ...toRefs(settings),
      y,
      linkGen,
      setCurrentPage,
    }
  },

}
</script>

<style lang="scss" scoped>
.pager_embed {
  position: relative;
  display: flex;
  justify-content: center;
  bottom: 40px;
  left: 0;
  right: 0;
  z-index: 99;
  margin-left: auto;
  margin-right: auto;

  opacity: 0;
  // transform: translateY(100px);
  transition: all .5s ease;

  &.show {
    opacity: 1;
    // transform: translateY(0)
  }
}

.pager_float {
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 40px;
  left: 0;
  right: 0;
  z-index: 99;

  opacity: 0;
  // transform: translateY(100px);
  transition: all .5s ease;

  &.show {
    opacity: 1;
    // transform: translateY(0)
  }
}
</style>
