<template>
  <div>
    <b-row>
      <b-col>
        <people-photos
          v-if="selectedTab === 'pics'"
          :people="people"
        />
        <people-suggestions
          v-if="selectedTab === 'list'"
          :people="people"
        />
        <top-list :posts="posts.slice(0, 5)" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import TopList from './TopList.vue'
import PeopleSuggestions from '../sports/PeopleSuggestions.vue'
import PeoplePhotos from '../sports/PeoplePhotos.vue'

export default {
  components: {
    // Card components
    BRow,
    BCol,

    // Post Types
    TopList,
    PeopleSuggestions,
    PeoplePhotos,
  },

  props: {
    posts: {
      type: Array,
      default: () => [],
      // validator: (prop) => prop.every(e => typeof e === 'string'),
    },
    people: {
      type: Array,
      default: () => [],
      // validator: (prop) => prop.every(e => typeof e === 'string'),
    },
    selectedTab: {
      type: String,
      default: 'pics',
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
