<template>
  <div>

    <team-card
      v-show="teamData !== undefined"
      :team-data="teamData"
      @selectedTab="(tab) => settings.selectedTab = tab"
    />

    <div style="display: inline-flex; flex-direction: row; flex-wrap: wrap; align-items: flex-start;">
      <h6
        v-if="settings.divider !== ''"
        class="kf_hd2"
      >
        <span>
          <router-link
            class="nav-link"
            :to="`/${divider}`"
            style="color: white;"
          >
            <b-img
              :src="require(`@/assets/images/icons/sports/${settings.image}.svg`)"
              height="30px"
              style="padding-right: 10px; padding-bottom: 5px;"
            />
            <strong class="titleClass">{{ settings.divider }}</strong>
          </router-link>
        </span>

        <div class="more-link">
          <router-link
            class="nav-link"
            :to="`/${settings.divider}`"
            style="color: white;"
          >
            {{ moreText }}
            <feather-icon
              icon="MoreHorizontalIcon"
              size="40"
            />
          </router-link>
        </div>
      </h6>

      <b-overlay
        :show="isLoading"
        class="match-height col-sm-12 col-md-12 col-lg-12 col-xl-9 col-12"
        opacity="0.7"
      >
        <div>
          <post-composite
            :posts="settings.posts"
            :title="teamName"
            title-extra="News"
            :title-extra-small="computedTitle"
          />

          <Pager
            :page="settings.theCurrentPage"
            :pages="settings.pages"
            :floating="false"
            @pager-page="updateCurrentPage"
          />
        </div>
      </b-overlay>

      <div
        v-if="includeSide === 'true'"
        class="side-bar col-sm-12 col-md-4 col-lg-4 col-xl-3 col-12"
        style="padding-left: 5px; padding-right: 5px;"
      >
        <vertical-container
          :posts="settings.posts"
          :people="people"
          :selected-tab="settings.selectedTab"
        />
      </div>
    </div>

    <ScrollToTop />
  </div>
</template>

<script>

import {
  // BRow,
  BImg,
  BOverlay,
} from 'bootstrap-vue'

import {
  reactive, computed, watch, toRefs,
} from '@vue/composition-api'

import { subject as an } from '@casl/ability'
import store from '@/store'

import Pager from '@core/components/pager/Pager.vue'
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import TeamCard from './TeamCard.vue'
import PostComposite from '../posts/PostComposite.vue'
import VerticalContainer from '../posts/VerticalContainer.vue'
import * as PostService from '../../services/PostService'

export default {
  components: {
    // Card components
    // BRow,
    BImg,
    BOverlay,

    Pager,
    ScrollToTop,
    TeamCard,
    PostComposite,
    VerticalContainer,
  },

  props: {
    includeSide: {
      type: String,
      default: 'true',
    },
  },

  setup(props, context) {
    const settings = reactive({
      image: '',
      search: '',
      divider: '',
      sport: '',
      selectedTab: 'pics',
      time: 0,
      count: 0,
      total: 0,
      posts: [],
      keywords: [],
      nicknames: [],
      pages: 1,
      currentPage: null,
      theCurrentPage: null,
      sportData: {},
    })

    function linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    }

    function updateCurrentPage(p) {
      settings.theCurrentPage = p
    }

    let currentPath = ''
    const teamName = computed(() => context.root.$route.meta.pageTitle)
    const teamData = computed(() => store.getters['appData/getTeamFromSports'](settings.sport, context.root.$route.meta.pageTitle))
    const isLoading = computed(() => store.state.loader.isLoading)
    const page = computed(() => parseInt(context.root.$route.query.page, 10) || 1)
    settings.sportData = computed(() => store.getters['appData/getSportData'](settings.sport))

    function getSearch(arr) {
      store.dispatch('loader/pending')

      settings.search = arr.join(' ')
      settings.keywords = context.root.$route.meta.keywords || []
      settings.nicknames = context.root.$route.meta.nicknames || []
      settings.sport = context.root.$route.meta.category || ''

      // settings.search = arr.join(' ')
      settings.search = `${settings.keywords.join(',')}  ${settings.nicknames.join(',')}`.trim()
      return PostService.getAllPostsFromCategoryWithSearch(settings.sport, settings.search, 50, page.value)
        .then(response => {
          // Have to Map the data using the CASL ability 'an' function to 'Post' items.
          settings.posts = response.data.posts.map(item => an('Post', item))
          settings.time = response.data.time
          settings.count = response.data.count
          settings.total = response.data.total
          settings.limit = response.data.limit

          if (settings.total > 0) {
            settings.pages = Math.ceil(settings.total / settings.limit)
          } else {
            settings.pages = 1
          }

          store.dispatch('loader/done')
        })
        // .catch(error => {
        //   console.log('\n---------------ERROR-----------------------------------------------')
        //   console.log(error)
        // })
    }

    const getAllPosts = async () => {
      const str = context.root.$route.path
      const arr = str.split('/').map(item => item.trim())
      await getSearch(arr)
    }

    watch(page, newVal => {
      /* eslint-disable no-console */
      console.log(newVal)
      /* eslint-enable no-console */

      const str = context.root.$route.path
      const arr = str.split('/').map(item => item.trim())
      getSearch(arr)
    }, {
      immediate: true,
    })

    // onMounted(getAllPosts)

    watch(() => {
      if (context.root.$route.path && currentPath !== context.root.$route.path) {
        // console.log('FIX/TODO/ZACZEK : this is being called twice right now due to watch()...figure out how to do only one call')
        getAllPosts()
        currentPath = context.root.$route.path
      }
    })

    const people = computed(() => {
      const tmpPeople = store.getters['appData/getPeople']
      const sports = settings.sport.split(',')
      return tmpPeople.filter(person => (props.sport === '' || sports.indexOf(person.sport) > -1))
    })

    const computedTitle = computed(() => {
      if (settings.theCurrentPage === null || settings.theCurrentPage === 0) {
        return ''
      }
      return `Page ${settings.theCurrentPage} of ${settings.pages} (${settings.total} Posts)`
    })

    return {
      // ...toRefs(data),
      ...toRefs(settings),
      linkGen,
      updateCurrentPage,
      settings,
      teamName,
      teamData,
      people,
      isLoading,
      computedTitle,
    }
  },
}
</script>

<style lang="scss" scoped>
.bg {
  background-image: url("../../../src/assets/images/nav/soccer.png") !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.side-bar {
  // height: 100vh;
  margin: 0 0 0 0px;

  @media screen {
    width: 100%;
  }

  // @media screen and (max-width: 1444px) {
  //    width: 10px;
  // }
}

.more-link {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: white;
}
</style>
